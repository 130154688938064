import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import SyncIcon from '@mui/icons-material/Sync';
import OrderPdfIcon from '@mui/icons-material/Archive';
import StateIcon from '@mui/icons-material/Bookmark';
import AddonsIcon from '@mui/icons-material/Extension';
import ExportIcon from '@mui/icons-material/GetApp';
import EditIcon from '@mui/icons-material/Edit';
import InvoicesIcon from '@mui/icons-material/Description';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import PaidIcon from '@mui/icons-material/MonetizationOn';
import CouriersIcon from '@mui/icons-material/LocalShipping';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewIcon from '@mui/icons-material/Folder';
import {ListScreen, Checkbox, Select, Packages, Order, Input} from '../components';
import {
    fetchOrders,
    cleanOrders,
    setUser,
    changeUserSetting,
    syncOrder,
    syncOrders,
    pdfOrder,
    pdfOrders,
    changeOrdersState,
    changeOrderState,
    createInvoice,
    createInvoices,
    paidOrder,
    paidOrders,
    deleteOrder,
    deleteOrders,
    addonOrders,
    editOrder,
    changeOrderData,
    sendOrders,
    sellerOrder,
    sellerOrders,
} from '../actions';
import { __, formatDate, request, plural, formatAmount, toNumber, getOrderButtons } from '../functions';
import {COOKIE_OFFER_NAME, COOKIE_READ_NAME, COOKIE_SETTINGS, ORDER_SOURCES} from '../config';
import '../assets/styles/orders.css';
import {JSPrintManager, WSStatus, ClientPrintJob, InstalledPrinter, PrintFilePDF, FileSourceType, ClientPrintJobGroup} from "jsprintmanager";

/**
 * Objednavky.
 */
class OrdersScreen extends ListScreen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Objednávky');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = { ...ListScreen.state, ...{
        readOffer: true,
        downloadedPdf: [],
        canceledPackages: [],
        loadingSync: false,
        loadingMplReport: false,
        loadingMplReportNew: false,
        loadingEbenicaExport: false,
        lightbox: {
            delete: false,
            paid: false,
            multiorderpdf: false,
            multistate: false,
            multiaddon: false,
            multiexport: false,
            multipdf: false,
            multidelete: false,
            state: false,
            printers: false,
            sellers: false,
            multisellers: false,
        },
    }};

    // Memory cache
    memoryCacheStates = null;
    memoryCacheHasInvoiceCreate = null;
    memoryCacheHasInvoicePdf = null;
    memoryCacheHasInvoicing = null;
    memoryCacheHasPermissionChangeState = null;
    memoryCacheHasPermissionView = null;
    memoryCacheHasPermissionPdfOrder = null;
    memoryCacheHasPermissionSync = null;
    memoryCacheHasPermissionPdf = null;
    memoryCacheHasPermissionCreateInvoice = null;
    memoryCacheHasPermissionPaid = null;
    memoryCacheHasPermissionSend = null;
    memoryCacheHasPermissionDelete = null;
    memoryCacheCourierSettingsIds = null;

    /**
     * Bol volany klik na objednavku.
     *
     * @type {boolean}
     */
    callViewClick = false;

    defaultState = {};

    /**
     * Komponenta bola pripojena.
     *
     * @return {boolean}
     */
    componentDidMount() {
        const { location, cookies } = this.props;

        // Vyhladavanie podla cisla objednavky
        const searchByNumber = location.pathname
            .replace('/orders/', '')
            .replace('/orders', '')
            .replace('/sellers-orders/', '')
            .replace('/sellers-orders', '');

        if (searchByNumber !== '') {
            // Vyhladavanie podla cisla
            this.defaultFiltered = { search: searchByNumber };
        }

        let mtokna = cookies.get('mtokna', COOKIE_SETTINGS);

        if (!_.isUndefined(mtokna)) {
            if (window.location.search.indexOf('mtokna') !== -1) {
                this.page = toNumber(mtokna.page);
                this.defaultFiltered = mtokna.filtered;
                this.defaultState = {
                    multiselectIds: mtokna.multiselectIds,
                    multiselectAll: mtokna.multiselectAll,
                    multiselectLocked: mtokna.multiselectLocked,
                };
            }

            cookies.remove('mtokna', COOKIE_SETTINGS);
        }

        return super.componentDidMount();
    }

    afterFetchList() {
        if (!_.has(this.props, 'user') || _.isEmpty(this.props.user)) {
            return;
        }

        const { user } = this.props;

        if (_.has(user, 'allow_offer') && _.includes(user.allow_offer, user.user_eshop_id)) {
            setTimeout(() => this.setState({ readOffer: this.readOffer() }), 1000);
        }

        if (!_.isEmpty(this.defaultState)) {
            setTimeout(() => this.setState(this.defaultState), 500);
        }
    }

    /**
     * Je validne pravo?
     *
     * @return {boolean}
     */
    isValidPermission() {
        return this.hasPermission('orders');
    }

    /**
     * Has settings.
     *
     * @return {boolean}
     */
    hasSettings() {
        const { user } = this.props;

        return !_.has(user, 'is_seller') || !user.is_seller;
    }

    /**
     * Vratime zoznam tagov.
     *
     * @return {Array}
     */
    getTags() {
        const { items, user } = this.props;
        const { loadingSync, loadingMplReport, loadingMplReportNew, loadingEbenicaExport } = this.state;

        // Vytiahneme zoznam aktivnych addonov
        const addons = _.reduce(
            items.user_addons,
            (result, addon_id) => (_.has(items.addons, addon_id) ? { ...result, [addon_id]: items.addons[addon_id] } : result),
            {}
        );

        const userEshopId = toNumber(user.user_eshop_id);

        return [
            window.location.pathname !== '/sellers-orders' ? (items.synchronized === '1970-01-01 00:00:00'
                ? { name: __('Úvodná synchronizácia prebehne v nasledujúcich minútach') }
                : { name: formatDate(items.synchronized, __('Synchronizované s eshopom o hh:ii')) }) : null,
            userEshopId > 0 && window.location.pathname !== '/sellers-orders' ? {
                name: !loadingSync ? __('Synchronizovať') : this.renderLoading(18, 'inherit'),
                onClick: () => this.sync(),
                className: `sync ${loadingSync ? 'sync-loading' : ''}`,
            } : null,
            _.includes([39], userEshopId) ? {
                name: __('Nová objednávka'),
                onClick: () => this.redirect('/orders/mtokna/new'),
            } : null,
            _.includes(addons, 'Spajame') ? {
                name: __('Príjemky'),
                onClick: () => window.open('https://woo.goodeshop.sk/pohoda-prijem.php', '_blank'),
            } : null,
            _.includes(addons, 'Spajame') ? {
                name: __('Výdajky'),
                onClick: () => window.open('https://woo.goodeshop.sk/pohoda-vydaj.php', '_blank'),
            } : null,
            _.includes([377,378], userEshopId) ? {
                name: !loadingMplReport ? __('MPL report - JMC') : this.renderLoading(18, 'inherit'),
                onClick: () => this.mplReport(),
            } : null,
            _.includes([377,378], userEshopId) ? {
                name: !loadingMplReportNew ? __('MPL report - Kelfur') : this.renderLoading(18, 'inherit'),
                onClick: () => this.mplReportNew(),
            } : null,
            _.includes([54,112,113,114,115,137], userEshopId) ? {
                name: !loadingEbenicaExport ? __('Export') : this.renderLoading(18, 'inherit'),
                onClick: () => this.ebenicaExport(),
            } : null,
        ];
    }

    /**
     * Vratime nazov tabulky.
     *
     * @return {string}
     */
    getTableName() {
        return 'orders';
    }

    showSellers(item) {
        const settings = {
            goodeshop_poznamka_predajca: _.has(item.data.meta_data, 'goodeshop_poznamka_predajca') ? item.data.meta_data.goodeshop_poznamka_predajca : '',
            goodeshop_poznamka_eshop: _.has(item.data.meta_data, 'goodeshop_poznamka_eshop') ? item.data.meta_data.goodeshop_poznamka_eshop : '',
            goodeshop_datum_odovzdania: _.has(item.data.meta_data, 'goodeshop_datum_odovzdania') ? item.data.meta_data.goodeshop_datum_odovzdania : '',
            goodeshop_vydajka: _.has(item.data.meta_data, 'goodeshop_vydajka') ? item.data.meta_data.goodeshop_vydajka : 'Nie',
            goodeshop_zauctovana: _.has(item.data.meta_data, 'goodeshop_zauctovana') ? item.data.meta_data.goodeshop_zauctovana : 'Nie',
            goodeshop_cislo_faktury: _.has(item.data.meta_data, 'goodeshop_cislo_faktury') ? item.data.meta_data.goodeshop_cislo_faktury : '',
        };

        this.showLightbox('sellers', { id: item.id, settings, loading: false });
    }

    closeSellers() {
        this.closeLightbox('sellers');
    }

    onChangeSellersSetting(key, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, sellers: { ...lightbox.sellers, settings: { ...lightbox.sellers.settings, [key]: value } } } });
    }

    onChangeMultiSellersSetting(key, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, multisellers: { ...lightbox.multisellers, data: { ...lightbox.multisellers.data, [key]: value } } } });
    }

    saveSellers() {
        const { lightbox } = this.state;
        const { sellerOrder } = this.props;

        this.setState({ lightbox: { ...lightbox, sellers: { ...lightbox.sellers, loading: true } } });

        sellerOrder(this, lightbox.sellers.id, lightbox.sellers.settings);
    }

    /**
     * Update sellers objednavok cez multiselect.
     */
    async multiSellers() {
        const { lightbox } = this.state;
        const { items, sellerOrders } = this.props;

        // Zavrieme lightbox
        this.closeLightbox('multisellers');

        // Zobrazime loading
        lightbox.multisellers.callbackLoading();

        await sellerOrders(this, lightbox.multisellers.ids, lightbox.multisellers.data, items.filtered);

        // Zavolame callback
        lightbox.multisellers.callback();
    }

    showPrinters(data) {
        console.log('print', 'show');

        this.showLightbox('printers', { items: {}, selected: '', data, loading: false });

        const printersCallback = (name, data) => this.showLightbox(name, data);

        JSPrintManager.auto_reconnect = true;
        JSPrintManager.start();

        var printInterval = setInterval(function() {
            if (JSPrintManager.websocket_status == WSStatus.Open) {
                JSPrintManager.getPrinters().then(function(e) {
                    const items = _.reduce(e, (result, item) => {
                        return { ...result, [item]: item };
                    }, {});

                    printersCallback('printers', { items, selected: !_.isEmpty(items) ? _.keys(items)[0] : '', data, loading: false });

                    clearInterval(printInterval);
                });
            }
        }, 200);
    }

    closePrinters() {
        this.closeLightbox('printers');
    }

    savePrinters() {
        const { lightbox } = this.state;

        if (!_.isEmpty(lightbox.printers) && lightbox.printers.selected !== '') {
            console.log('printers');
            this.onChangePrinterLoading(true);

            const callback = () => this.closePrinters();
            const selectedPrinter = lightbox.printers.selected;

            request(`/orders/makePublic/${lightbox.printers.data.type}/${lightbox.printers.data.id}`).then(response => {
                const { status, data } = response.data;

                if (status === 'error') {
                    this.showSnackbar('error', __('Nepodarilo sa stiahnuť pdf'));
                    callback();
                    return;
                }

                const publicLink = data.link;

                JSPrintManager.auto_reconnect = true;
                JSPrintManager.start();

                var printInterval = setInterval(function() {
                    if (JSPrintManager.websocket_status == WSStatus.Open) {
                        var cpj2 = new ClientPrintJob();
                        cpj2.clientPrinter = new InstalledPrinter(selectedPrinter);
                        var my_file2 = new PrintFilePDF(publicLink, FileSourceType.URL, `${lightbox.printers.data.id}.pdf`, 1);
                        cpj2.files.push(my_file2);
                        var cpjg = new ClientPrintJobGroup();
                        cpjg.jobs.push(cpj2);
                        cpjg.sendToClient();

                        clearInterval(printInterval);
                        callback();
                    }
                }, 200);
            });

            return;
        }

        this.closePrinters();
    }

    editMtOkna(id) {
        const { cookies } = this.props;
        const { filtered, multiselectIds, multiselectAll, multiselectLocked } = this.state;

        const cookie_data = {
            page: this.page,
            filtered,
            multiselectIds,
            multiselectAll,
            multiselectLocked,
        };
        cookies.set('mtokna', JSON.stringify(cookie_data), COOKIE_SETTINGS);

        setTimeout(() => { this.redirect(`/orders/mtokna/${id}`); }, 200);
    }

    /**
     * Vratime bunky.
     *
     * @param {Object} item
     *
     * @return {Array}
     */
    getCells(item) {
        const { user, items } = this.props;
        const { downloadedPdf, canceledPackages } = this.state;

        if (this.memoryCacheStates === null) {
            // Nemame v memory cache
            // Naformatujeme zoznam stavov
            this.memoryCacheStates = _.reduce(items.states, (result, { id, name }) => ({ ...result, [id]: name }), {});
        }

        if (this.memoryCacheHasInvoiceCreate === null) {
            // Nemame v memory cache
            this.memoryCacheHasInvoiceCreate = this.hasAccountingAction('create');
        }

        if (this.memoryCacheHasInvoicePdf === null) {
            // Nemame v memory cache
            this.memoryCacheHasInvoicePdf = this.hasAccountingAction('pdf');
        }

        if (this.memoryCacheHasInvoicing === null) {
            // Nemame v memory cache
            this.memoryCacheHasInvoicing = this.hasAccountingAction('invoicing');
        }

        if (this.memoryCacheHasPermissionChangeState === null) {
            // Nemame v memory cache
            this.memoryCacheHasPermissionChangeState = this.hasPermission('orders-changestate');
        }

        if (this.memoryCacheHasPermissionView === null) {
            // Nemame v memory cache
            this.memoryCacheHasPermissionView = this.hasPermission('orders-view');
        }

        if (this.memoryCacheHasPermissionPdfOrder === null) {
            // Nemame v memory cache
            this.memoryCacheHasPermissionPdfOrder = this.hasPermission('orders-pdf');
        }

        if (this.memoryCacheHasPermissionSync === null) {
            // Nemame v memory cache
            this.memoryCacheHasPermissionSync = this.hasPermission('orders-sync');
        }

        if (this.memoryCacheHasPermissionPdf === null) {
            // Nemame v memory cache
            this.memoryCacheHasPermissionPdf = this.hasPermission('invoices-pdf');
        }

        if (this.memoryCacheHasPermissionCreateInvoice === null) {
            // Nemame v memory cache
            this.memoryCacheHasPermissionCreateInvoice = this.hasPermission('invoices-create');
        }

        if (this.memoryCacheHasPermissionPaid === null) {
            // Nemame v memory cache
            this.memoryCacheHasPermissionPaid = this.hasPermission('orders-paid');
        }

        if (this.memoryCacheHasPermissionSend === null) {
            // Nemame v memory cache
            this.memoryCacheHasPermissionSend = this.hasPermission('orders-send');
        }

        if (this.memoryCacheHasPermissionDelete === null) {
            // Nemame v memory cache
            this.memoryCacheHasPermissionDelete = this.hasPermission('orders-delete');
        }

        if (this.memoryCacheCourierSettingsIds === null) {
            // Vytiahneme zoznam idciek kurierov
            this.memoryCacheCourierSettingsIds = _.reduce(
                items.user_couriers,
                (result, { id }) => ([ ...result, ...[id.toString()] ]),
                []
            );
        }

        // Vytiahneme tlacitka pre objednavku
        let buttons = getOrderButtons(item, {
            settings: items.settings,
            downloadedPdf,
            canceledPackages,
            hasInvoiceCreate: this.memoryCacheHasInvoiceCreate,
            hasInvoicePdf: this.memoryCacheHasInvoicePdf,
            hasInvoicing: this.memoryCacheHasInvoicing,
            hasPermissionView: this.memoryCacheHasPermissionView,
            hasPermissionPdfOrder: this.memoryCacheHasPermissionPdfOrder,
            hasPermissionSync: this.memoryCacheHasPermissionSync,
            hasPermissionPdf: this.memoryCacheHasPermissionPdf,
            hasPermissionCreateInvoice: this.memoryCacheHasPermissionCreateInvoice,
            hasPermissionPaid: this.memoryCacheHasPermissionPaid,
            hasPermissionSend: this.memoryCacheHasPermissionSend,
            hasPermissionDelete: this.memoryCacheHasPermissionDelete,
            userCouriers: items.user_couriers,
            couriers: items.couriers,
            courierSettings: items.courier_settings,
            courierSettingsIds: this.memoryCacheCourierSettingsIds,
        });

        // Nastavime callbacky
        if (typeof buttons.view !== 'undefined') {
            buttons.view.callback = (callbackLoading, callback) => this.showDrawer('order', {
                id: item.id,
                buttons,
                callbackLoading,
                callback,
            });

            if (
                _.includes([65, 66, 118, 119, 120, 121, 122, 123, 124, 125, 126, 377, 378, 572], user.user_eshop_id)
                && !_.isEmpty(item.data.client.delivery_note)
            ) {
                // TODO:: zapracovat farebnost podla pravidiel
                buttons.view.icon = <ViewIcon color="primary" />
            }

            if (
                !_.isEmpty(this.defaultFiltered)
                && _.has(this.defaultFiltered, 'search')
                && this.defaultFiltered.search.toString() === item.data.number.toString()
                && !this.callViewClick
            ) {
                // Vyhladavame podla cisla objednavky
                this.callViewClick = true;

                // Zobrazime view
                buttons.view.callback(() => {}, () => {});
            }
        }

        // Nastavime callbacky
        if (typeof buttons.pdfOrder !== 'undefined') {
            buttons.pdfOrder.callback = (callbackLoading, callback) => this.pdfOrder(item.id, callbackLoading, callback);
        }

        if (typeof buttons.sync !== 'undefined') {
            buttons.sync.callback = (callbackLoading, callback) => this.syncOrder(item.id, callbackLoading, callback);
        }

        if (typeof buttons.createInvoice !== 'undefined') {
            buttons.createInvoice.callback = (callbackLoading, callback) => this.createInvoice(item.id, callbackLoading, callback);
        }

        if (typeof buttons.pdfLink !== 'undefined') {
            buttons.pdfLink.callback = () => window.open(buttons.pdfLink.options.pdf_link, '_blank');
        }

        if (typeof buttons.pdf !== 'undefined') {
            buttons.pdf.callback = (callbackLoading, callback) => this.pdf(buttons.pdf.options.invoiceId, item.id, callbackLoading, callback);

            if (_.has(buttons.pdf.options, 'isMserverWaiting') && buttons.pdf.options.isMserverWaiting) {
                buttons.pdf.callback = () => {};
            }

            if (_.includes([21, 407], user.user_eshop_id)) {
                buttons.pdf.callback = () => this.showPrinters({
                    type: 'invoice',
                    id: buttons.pdf.options.invoiceId,
                });
            }
        }

        if (typeof buttons.paid !== 'undefined') {
            buttons.paid.callback = !buttons.paid.options.paid
                ? (callbackLoading, callback) => this.confirmPaid(item.id, callbackLoading, callback)
                : () => {};
        }

        if (typeof buttons.send !== 'undefined') {
            buttons.send.callback = buttons.send.options.packagePayment || buttons.send.options.packageImport
                ? () => {}
                : (buttons.send.options.packageSend
                    ? () => this.onClickPackageNumber(item.package.number)
                    : () => this.showDrawer('packages', { ids: item.id })
            );

            if (_.includes([377,378], user.user_eshop_id) && buttons.send.options.packageSend) {
                buttons = { ...buttons, cancelSend: {
                        name: __('Kliknutím zrušíte zásielku'),
                        icon: <CouriersIcon color="error" />,
                        callback: (callbackLoading, callback) => this.mplCancel(item.id, callbackLoading, callback),
                        options: {},
                    } };
            }

            if (_.includes([142,143,430], user.user_eshop_id) && buttons.send.options.packageSend) {
                buttons.send.icon = <CouriersIcon color="primary" />
            } else if (_.includes([142,143,430], user.user_eshop_id) && buttons.send.options.packageError) {
                buttons.send.icon = <CouriersIcon color="orange" />
            }
        }

        if (typeof buttons.delete !== 'undefined') {
            buttons.delete.callback = (callbackLoading, callback) => this.confirmDelete(item.id, callbackLoading, callback);
        }

        const cellSettings = this.getCellsSettings();
        const cellOptions = this.getCellsOptions();

        let source = '';

        if (item.data.source !== '' && _.has(ORDER_SOURCES, item.data.source)) {
            // Je vyplneny zdroj
            const sourceConfig = ORDER_SOURCES[item.data.source];

            source = <div className="source" style={{ backgroundColor: sourceConfig.background }}>
                {sourceConfig.name}
            </div>;
        }

        if ((user.is_seller || user.is_seller_owner) && window.location.pathname === '/sellers-orders') {
            return [
                <div>
                    <div style={{ backgroundColor: item.data.state.color }} />
                    <div>
                        {this.getCellValue(cellOptions[cellSettings.settings[0][0]].get(item))}
                    </div>
                    {this.getCellValue(cellOptions[cellSettings.settings[0][1]].get(item))}
                    {this.getCellValue(cellOptions[cellSettings.settings[0][2]].get(item))}
                </div>,
                <div>
                    <div>{user.is_seller ? _.truncate(_.split(item.data.client.name, ' ')[0], { length: 32 }) : _.truncate(item.data.client.name, { length: 32 })}</div>
                    <div>{!user.is_seller ? _.truncate(item.data.client.email, { length: 32 }) : '-'}</div>
                    <div>{!user.is_seller ? item.data.client.phone : '-'}</div>
                </div>,
                <div>
                    <div>{_.truncate(item.data.meta_data.inv_custom_picked_shop_name, { length: 32 })}</div>
                    <div>{_.truncate(item.data.meta_data.inv_custom_picked_shop_address, { length: 32 })}</div>
                    <div>{_.truncate(_.has(item.data.meta_data, 'goodeshop_poznamka_predajca') && !_.isEmpty(item.data.meta_data.goodeshop_poznamka_predajca) ? item.data.meta_data.goodeshop_poznamka_predajca : '-', { length: 32 })}</div>
                </div>,
                <div>
                    <div>{_.has(item.data.meta_data, 'goodeshop_datum_odovzdania') && !_.isEmpty(item.data.meta_data.goodeshop_datum_odovzdania) ? __('Áno') : __('Nie')}</div>
                    <div>{_.has(item.data.meta_data, 'goodeshop_datum_odovzdania') && !_.isEmpty(item.data.meta_data.goodeshop_datum_odovzdania) ? formatDate(item.data.meta_data.goodeshop_datum_odovzdania, 'dd.mm.yyyy') : '-'}</div>
                    <div>{_.truncate(_.has(item.data.meta_data, 'goodeshop_poznamka_eshop') && !_.isEmpty(item.data.meta_data.goodeshop_poznamka_eshop) && user.is_seller_owner ? item.data.meta_data.goodeshop_poznamka_eshop : '-', { length: 32 })}</div>
                </div>,
                <div>
                    <div>{_.has(item.data.meta_data, 'goodeshop_vydajka') && !_.isEmpty(item.data.meta_data.goodeshop_vydajka) ? item.data.meta_data.goodeshop_vydajka : '-'}</div>
                    <div>{_.has(item.data.meta_data, 'goodeshop_zauctovana') && !_.isEmpty(item.data.meta_data.goodeshop_zauctovana) ? item.data.meta_data.goodeshop_zauctovana : '-'}</div>
                    <div>{_.has(item.data.meta_data, 'goodeshop_cislo_faktury') && !_.isEmpty(item.data.meta_data.goodeshop_cislo_faktury) ? item.data.meta_data.goodeshop_cislo_faktury : '-'}</div>
                </div>,
                <div>
                    {this.memoryCacheHasPermissionChangeState && !this.isMobile() ? <div>
                        <Select
                            options={this.memoryCacheStates}
                            value={item.data.state.id}
                            onChange={value => this.confirmState(item.id, value, '')}
                            allowEmpty={false}
                            variant="standard"
                        />
                    </div> : null}
                    <div>
                        {this.renderTableButton(
                            item.id,
                            __('Predajca'),
                            <EditIcon />,
                            () => this.showSellers(item),
                            {}
                        )}
                        {this.renderTableButtons(item.id, buttons, ['sync', 'pdfLink', 'pdf', 'createInvoice', 'paid', 'send', 'delete'], false)}
                    </div>
                </div>,
            ];
        }

        return [
            <div>
                <div style={{ backgroundColor: item.data.state.color }} />
                <div>
                    {this.getCellValue(cellOptions[cellSettings.settings[0][0]].get(item))}
                    {source}
                </div>
                {this.getCellValue(cellOptions[cellSettings.settings[0][1]].get(item))}
                {this.getCellValue(cellOptions[cellSettings.settings[0][2]].get(item))}
            </div>,
            <div>
                {this.getCellValue(cellOptions[cellSettings.settings[1][0]].get(item))}
                {this.getCellValue(cellOptions[cellSettings.settings[1][1]].get(item))}
                {this.getCellValue(cellOptions[cellSettings.settings[1][2]].get(item))}
            </div>,
            <div>
                {this.getCellValue(cellOptions[cellSettings.settings[2][0]].get(item))}
                {this.getCellValue(cellOptions[cellSettings.settings[2][1]].get(item))}
                {this.getCellValue(cellOptions[cellSettings.settings[2][2]].get(item))}
            </div>,
            <div>
                {this.memoryCacheHasPermissionChangeState && !this.isMobile() ? <div>
                    <Select
                        options={this.memoryCacheStates}
                        value={item.data.state.id}
                        onChange={value => this.confirmState(item.id, value, buttons.view.name)}
                        allowEmpty={false}
                        variant="standard"
                    />
                </div> : null}
                <div>
                    {_.includes([38,39,42], user.user_eshop_id) ? (item.data.source === 'manual' ? this.renderTableButton(
                        item.id,
                        __('Upraviť'),
                        <EditIcon />,
                        () => this.redirect(`/orders/mtokna/new/${item.id}`),
                        {}
                    ) : this.renderTableButton(
                        item.id,
                        __('Upraviť'),
                        <EditIcon />,
                        () => this.editMtOkna(item.id),
                        {}
                    )) : null}
                    {this.renderTableButtons(item.id, buttons, this.isMobile() ? ['sync', 'paid', 'delete', 'pdfOrder'] : [])}
                </div>
            </div>,
        ];
    }

    /**
     * Vratime options buniek.
     *
     * @return {Object}
     */
    getCellsOptions() {
        return {
            number: {
                label: __('Číslo'),
                get: item => item.data.number,
            },
            amount: {
                label: __('Suma'),
                get: item => formatAmount(item.data.amount, item.data.currency),
            },
            created: {
                label: __('Dátum'),
                get: item => formatDate(item.data.date),
            },
            client_name: {
                label: __('Zákaznik'),
                get: item => _.truncate(item.data.client.name, { length: 32 }),
            },
            client_delivery_name: {
                label: __('Zákaznik (dodanie)'),
                get: item => _.truncate(item.data.client.delivery_name, { length: 32 }),
            },
            client_contact_name: {
                label: __('Kontaktná osoba'),
                get: item => _.truncate(item.data.client.contact_name, { length: 32 }),
            },
            client_delivery_contact_name: {
                label: __('Kontaktná osoba (dodanie)'),
                get: item => _.truncate(item.data.client.delivery_contact_name, { length: 32 }),
            },
            client_company: {
                label: __('Firemné údaje'),
                get: item => !_.isEmpty(item.data.client.ico)
                    ? _.truncate(`${__('IČO')}: ${item.data.client.ico}, ${__('DIČ')}: ${item.data.client.dic}, ${__('IČ DPH')}: ${item.data.client.ic_dph}`, { length: 32 })
                    : '',
            },
            client_address: {
                label: __('Fakturačná adresa'),
                get: item => _.truncate(`${item.data.client.address}, ${item.data.client.city}`, { length: 32 }),
            },
            client_delivery_address: {
                label: __('Dodacia adresa'),
                get: item => _.truncate(`${item.data.client.delivery_address}, ${item.data.client.delivery_city}`, { length: 32 }),
            },
            client_phone: {
                label: __('Tel. číslo'),
                get: item => item.data.client.phone,
            },
            client_delivery_phone: {
                label: __('Tel. číslo (dodanie)'),
                get: item => item.data.client.delivery_phone,
            },
            client_email: {
                label: __('E-mail'),
                get: item => _.truncate(item.data.client.email, { length: 32 }),
            },
            client_note: {
                label: __('Poznámka'),
                get: item => _.truncate(item.data.client.delivery_note, { length: 32 }),
            },
            delivery_name: {
                label: __('Dodanie'),
                get: item => _.truncate(item.data.delivery_type_name, { length: 32 }),
            },
            payment_name: {
                label: __('Platba'),
                get: item => _.truncate(item.data.payment_type_name, { length: 32 }),
            },
            internal_comment: {
                label: __('Interná poznámka'),
                get: item => _.truncate(item.data.internal_comment, { length: 32 }),
            },
            package_number: {
                label: __('Číslo zásielky'),
                get: item => item.package.number.toString().indexOf('-') === -1 ? item.package.number : '',
            },
        };
    }

    /**
     * Vratime multiselect.
     *
     * @return {Array}
     */
    getMultiselect() {
        const { items, user } = this.props;

        // Naformatujeme zoznam stavov
        const states = _.reduce(items.states, (result, { id, name }) => ({ ...result, [id]: name }), {});

        // Vytiahneme zoznam aktivnych addonov
        const addons = _.reduce(
            items.user_addons,
            (result, addon_id) => (_.has(items.addons, addon_id) ? { ...result, [addon_id]: items.addons[addon_id] } : result),
            {}
        );

        // Vytiahneme zoznam exportov
        const exports = _.reduce(items.exports, (result, { id, name, def }) => toNumber(def) === 0 ? ({ ...result, [id]: name }) : result, {});

        if (window.location.pathname === '/sellers-orders') {
            return [
                {
                    confirm: (ids, callback, callbackLoading) => this.showLightbox('multiorderpdf', {
                        ids,
                        callback,
                        callbackLoading,
                    }),
                    icon: <OrderPdfIcon />,
                    text: __('PDF objednávok'),
                    limit: 200,
                    permission: 'orders-pdf',
                },
                user.is_seller_owner ? {
                    confirm: (ids, callback, callbackLoading) => this.showLightbox('multisellers', {
                        ids,
                        callback,
                        callbackLoading,
                        data: {
                            goodeshop_poznamka_eshop: '',
                            goodeshop_zauctovana: '',
                            goodeshop_cislo_faktury: '',
                        }
                    }),
                    icon: <EditIcon />,
                    text: __('Predajca'),
                    limit: 50,
                } : null,
            ];
        }

        return [
            !_.isEmpty(addons) ? {
                confirm: (ids, callback, callbackLoading) => this.showLightbox('multiaddon', {
                    ids,
                    callback,
                    callbackLoading,
                    addonId: _.keys(addons)[0],
                }),
                icon: <AddonsIcon />,
                text: __('Doplnok'),
            } : null,
            !_.isEmpty(exports) ? {
                confirm: (ids, callback, callbackLoading) => this.showLightbox('multiexport', {
                    ids,
                    callback,
                    callbackLoading,
                    exportId: _.keys(exports)[0],
                }),
                icon: <ExportIcon />,
                text: __('Export'),
            } : null,
            {
                callback: (ids, callback) => this.multiSync(ids, callback),
                icon: <SyncIcon />,
                text: __('Synchronizovať'),
                limit: 100,
                permission: 'orders-sync',
            },
            {
                confirm: (ids, callback, callbackLoading) => this.showLightbox('multiorderpdf', {
                    ids,
                    callback,
                    callbackLoading,
                }),
                icon: <OrderPdfIcon />,
                text: __('PDF objednávok'),
                limit: 200,
                permission: 'orders-pdf',
            },
            {
                confirm: (ids, callback, callbackLoading) => this.showLightbox('multistate', {
                    ids,
                    callback,
                    callbackLoading,
                    stateId: _.keys(states)[0],
                }),
                icon: <StateIcon />,
                text: __('Zmeniť stav'),
                permission: 'orders-changestate',
            },
            this.hasAccountingAction('create') ? {
                callback: (ids, callback) => this.multiInvoices(ids, callback),
                icon: <InvoicesIcon />,
                text: __('Vystaviť faktúry'),
                validation: item => !this.hasInvoice(item),
                permission: 'invoices-create',
            } : null,
            this.hasAccountingAction('pdf') || _.includes([377, 378], user.user_eshop_id) ? {
                confirm: (ids, callback, callbackLoading) => this.showLightbox('multipdf', {
                    ids,
                    callback,
                    callbackLoading,
                }),
                icon: <PdfIcon />,
                text: __('Stiahnuť PDF'),
                validation: item => this.hasInvoice(item),
                limit: 50,
                permission: 'invoices-pdf',
            } : null,
            {
                callback: (ids, callback) => this.multiPaid(ids, callback),
                icon: <PaidIcon />,
                text: __('Uhradiť'),
                validation: item => item.invoice_status !== 'paid',
                permission: 'orders-paid',
            },
            {
                confirm: (ids, callback) => this.showDrawer('packages', { ids, filtered: items.filtered, callback }),
                icon: <CouriersIcon />,
                text: __('Odoslať prepravcovi'),
                limit: 300,
                permission: 'orders-send',
            },
            {
                confirm: (ids, callback, callbackLoading) => this.showLightbox('multidelete', {
                    ids,
                    callback,
                    callbackLoading,
                }),
                icon: <DeleteIcon />,
                text: __('Zmazať'),
                permission: 'orders-delete',
            },
        ];
    }

    /**
     * Vratime filter.
     *
     * @return {Object}
     */
    getFilter() {
        const { items } = this.props;

        return _.reduce(items.filter, (result, options, key) => {
            switch (key) {
                case 'invoice_id':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Faktúra'),
                        value: '',
                        options: {
                            'null': __('Bez faktúry'),
                            'not_null': __('S faktúrou'),
                        },
                    }}};

                case 'has_package':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Zásielka'),
                        value: '',
                        options: {
                            0: __('Neodoslané'),
                            1: __('Odoslané'),
                        },
                        strict: false,
                    }}};

                case 'exported':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Exportované'),
                        value: '',
                        options: {
                            0: __('Nie'),
                            1: __('Áno'),
                        },
                        strict: false,
                    }}};

                case 'sellers_send':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Odovzdané'),
                        value: '',
                        options: {
                            0: __('Nie'),
                            1: __('Áno'),
                        },
                        strict: false,
                    }}};

                case 'sellers_invoiced':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Výdajka'),
                        value: '',
                        options: {
                            0: __('Nie'),
                            1: __('Áno'),
                        },
                        strict: false,
                    }}};

                case 'sellers_accounted':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Zúčtované'),
                        value: '',
                        options: {
                            0: __('Nie'),
                            1: __('Áno'),
                        },
                        strict: false,
                    }}};

                case 'invoices-status_pdf':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('PDF'),
                        value: '',
                        options: {
                            'newly': __('Nové'),
                            'download': __('Prečítané'),
                        },
                    }}};

                case 'state_id':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Stav'),
                        value: '',
                        options,
                        multiple: true,
                    }}};

                case 'payment_type_id':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Typ úhrady'),
                        value: '',
                        options,
                        multiple: true,
                    }}};

                case 'delivery_type_id':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Typ dodania'),
                        value: '',
                        options,
                        multiple: true,
                    }}};

                case 'date-from':
                    return { ...result, ...{ [key]: {
                        type: 'date',
                         name: __('Dátum od'),
                         value: null,
                    }}};

                case 'date-to':
                    return { ...result, ...{ [key]: {
                        type: 'date',
                        name: __('Dátum do'),
                        value: null,
                    }}};

                case 'search':
                    return { ...result, ...{ [key]: {
                        type: 'input',
                        name: __('Hľadať'),
                        value: '',
                    }}};

                case 'sellers_email':
                    return { ...result, ...{ [key]: {
                        type: 'input',
                        name: __('Email'),
                        value: '',
                    }}};

                case 'sellers_name':
                    return { ...result, ...{ [key]: {
                        type: 'input',
                        name: __('Názov'),
                        value: '',
                    }}};

                case 'sellers_invoice':
                    return { ...result, ...{ [key]: {
                        type: 'input',
                        name: __('Faktúra'),
                        value: '',
                    }}};

                default:
                    return result;
            }
        }, {});
    }

    /**
     * Vratime empty text.
     *
     * @return {string}
     */
    getEmptyText() {
        return __('Zatiaľ nemáte žiadne objednávky');
    }

    /**
     * Ma fakturu?
     *
     * @param {Object} item
     * @return {boolean}
     */
    hasInvoice(item) {
        const { items } = this.props;

        return !_.isEmpty(item.invoice)
            || (
                !_.isEmpty(items.settings.meta_data_pdf_link)
                && _.has(item.data.meta_data, items.settings.meta_data_pdf_link)
                && !_.isEmpty(item.data.meta_data[items.settings.meta_data_pdf_link])
            );
    }

    /**
     * Synchronizacia.
     */
    mplReport() {
        this.setState({ loadingMplReport: true });

        request('/user-labels/mplReport').then(response => {
            const { status, data } = response.data;

            this.setState({ loadingMplReport: false });

            if (status === 'error') {
                this.showSnackbar('error', __('Nepodarilo sa stiahnuť report'));
                return;
            }

            // Stiahneme subor
            window.location = data.link;
        });
    }

    /**
     * Synchronizacia.
     */
    ebenicaExport() {
        this.setState({ loadingEbenicaExport: true });

        request('/user-eshops/ebenicaExport/orders').then(response => {
            const { status, data } = response.data;

            this.setState({ loadingEbenicaExport: false });

            if (status === 'error') {
                this.showSnackbar('error', __('Nepodarilo sa stiahnuť export'));
                return;
            }

            this.showSnackbar('success', __('Export vám bude odoslaný na email do pár minút'));
        });
    }

    /**
     * Synchronizacia.
     */
    mplReportNew() {
        this.setState({ loadingMplReportNew: true });

        request('/user-labels/mplReport/new').then(response => {
            const { status, data } = response.data;

            this.setState({ loadingMplReportNew: false });

            if (status === 'error') {
                this.showSnackbar('error', __('Nepodarilo sa stiahnuť report'));
                return;
            }

            // Stiahneme subor
            window.location = data.link;
        });
    }

    /**
     * Mpl cancel.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    async mplCancel(id, callbackLoading, callback) {
        if (window.confirm('Naozaj?')) {
            // Zavolame loading
            callbackLoading();

            await request(`/user-labels/mplCancel/${id}`).then(response => {
                const { status, data } = response.data;

                if (status === 'error') {
                    this.showSnackbar('error', __('Nepodarilo sa zrušiť zásielku'));
                    return;
                }

                this.setState({ canceledPackages: this.getCanceledPackages([id]) });
            });

            // Zavolame callback
            callback();
        }
    }

    /**
     * Synchronizacia.
     */
    sync() {
        this.setState({ loadingSync: true });

        request('/user-eshops/syncOrders').then(response => {
            const { status, data } = response.data;

            this.setState({ loadingSync: false });

            // Vycistime zoznam
            this.cleanList();

            const { items } = this.props;
            const count = status === 'success' ? data.items.length : 0;

            // Nacitame prvu stranku
            this.fetchList(1, 0, items.filtered, () => this.showSnackbar('success', `${count} ${plural(count, [
                __('objednávka bola načítaná'),
                __('objednávky boli načítané'),
                __('objednávok bolo načítaných'),
            ])}`));
        });
    }

    /**
     * Event po kliku na cislo zasielky.
     *
     * @param {string} number
     */
    onClickPackageNumber(number) {
        // Nastavime cislo do clipboardu
        navigator.clipboard.writeText(number);

        this.showSnackbar('success', __('Skopirované do schránky'));
    }

    /**
     * Pridame stiahnute pdf.
     *
     * @param {Array} ids
     *
     * @return {Array}
     */
    getDownloadedPdf(ids) {
        let { downloadedPdf } = this.state;

        _.each(ids, id => {
            downloadedPdf = [ ...downloadedPdf, toNumber(id) ];
        });

        return downloadedPdf;
    }

    /**
     * Pridame canceled packages.
     *
     * @param {Array} ids
     *
     * @return {Array}
     */
    getCanceledPackages(ids) {
        let { canceledPackages } = this.state;

        _.each(ids, id => {
            canceledPackages = [ ...canceledPackages, toNumber(id) ];
        });

        return canceledPackages;
    }

    /**
     * Event po zmene stavu objednavky.
     */
    async changeState() {
        const { lightbox } = this.state;
        const { changeOrderState } = this.props;

        // Zavrieme lightbox
        this.closeLightbox('state');

        // Zobrazime loading
        this.loadingTableButton(this.getLoadingTableButtonId(lightbox.state.orderId, lightbox.state.viewText));

        // Zmenime stav
        await changeOrderState(this, lightbox.state.orderId, lightbox.state.stateId);

        // Zrusime loading table button
        this.clearTableButton();
    }

    /**
     * Zobrazime lightbox na zmenu stavu.
     *
     * @param {number} orderId
     * @param {string} stateId
     * @param {string} viewText
     */
    confirmState(orderId, stateId, viewText) {
        this.showLightbox('state', { orderId, stateId, viewText });
    }

    /**
     * Zobrazime lightbox na uhradu.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    confirmPaid(id, callbackLoading, callback) {
        this.showLightbox('paid', { id, callbackLoading, callback });
    }

    /**
     * Zobrazime lightbox na zmazanie.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    confirmDelete(id, callbackLoading, callback) {
        this.showLightbox('delete', { id, callbackLoading, callback });
    }

    /**
     * Stiahnutie pdf.
     *
     * @param {number} id
     * @param {number} orderId
     * @param {function} callbackLoading
     * @param {function} callback
     */
    async pdf(id, orderId, callbackLoading, callback) {
        // Zavolame loading
        callbackLoading();

        await request(`/invoices/pdf/${id}`).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                this.showSnackbar('error', __('Nepodarilo sa stiahnuť pdf'));
                return;
            }

            this.setState({ downloadedPdf: this.getDownloadedPdf([orderId]) });

            if (data.link.indexOf('inline') !== -1) {
                // Chceme inline
                window.open(data.link, '_blank');
                return;
            }

            // Stiahneme subor
            window.location = data.link;
        });

        // Zavolame callback
        callback();
    }

    /**
     * Zmazanie objednavky.
     */
    async delete() {
        const { deleteOrder, items } = this.props;
        const { lightbox } = this.state;

        if (this.isDemo()) {
            this.showSnackbar('error', __('Objednávku nie je možné zmazať na DEMO účte'))
            this.closeLightbox('delete');
            return;
        }

        // Zavolame loading
        lightbox.delete.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('delete');

        // Zmazeme objednavku
        await deleteOrder(this, lightbox.delete.id);

        // Zavolame callback
        lightbox.delete.callback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetchList(1, 0, items.filtered);
    }

    /**
     * Uhradenie objednavky.
     */
    async paid() {
        const { paidOrder } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.paid.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('paid');

        // Uhradime
        await paidOrder(this, lightbox.paid.id);

        // Zavolame callback
        lightbox.paid.callback();
    }

    /**
     * Stiahnutie order pdf.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    async pdfOrder(id, callbackLoading, callback) {
        const { pdfOrder } = this.props;

        // Zavolame loading
        callbackLoading();

        // Zavolame callback
        await pdfOrder(this, id, this.getPdfOrderExportId());

        // Zavolame callback
        callback();
    }

    /**
     * Synchronizacia objednavky.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    async syncOrder(id, callbackLoading, callback) {
        const { syncOrder } = this.props;

        // Zavolame loading
        callbackLoading();

        // Synchronizacia objednavky
        await syncOrder(this, id);

        // Zavolame callback
        callback();
    }

    /**
     * Vytvorenie faktury.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    async createInvoice(id, callbackLoading, callback) {
        const { createInvoice } = this.props;

        // Zavolame loading
        callbackLoading();

        // Vytvorime fakturu
        await createInvoice(this, id);

        // Zavolame callback
        callback();
    }

    /**
     * Synchronizacia objednavok cez multiselect.
     *
     * @param {string} ids
     * @param {function} callback
     */
    async multiSync(ids, callback) {
        const { syncOrders, items } = this.props;

        // Synchronizujeme
        await syncOrders(this, ids, items.filtered);

        // Zavolame callback
        callback();
    }

    /**
     * Stiahnutie pdf objednavok cez multiselect.
     */
    async multiOrderPdf() {
        const { lightbox } = this.state;
        const { items, pdfOrders } = this.props;

        // Zavrieme lightbox
        this.closeLightbox('multiorderpdf');

        // Zobrazime loading
        lightbox.multiorderpdf.callbackLoading();

        await pdfOrders(this, lightbox.multiorderpdf.ids, this.getPdfOrderExportId(), items.filtered);

        // Zavolame callback
        lightbox.multiorderpdf.callback();
    }

    /**
     * Zmena stavu objednavok cez multiselect.
     */
    async multiChangeState() {
        const { lightbox } = this.state;
        const { items, changeOrdersState } = this.props;

        // Zavrieme lightbox
        this.closeLightbox('multistate');

        // Zobrazime loading
        lightbox.multistate.callbackLoading();

        // Zmenime stav
        await changeOrdersState(this, lightbox.multistate.ids, lightbox.multistate.stateId, items.filtered);

        // Zavolame callback
        lightbox.multistate.callback();
    }

    /**
     * Zavolanie addonu cez multiselect.
     */
    async multiAddon() {
        const { lightbox } = this.state;
        const { addonOrders, items } = this.props;

        // Zavrieme lightbox
        this.closeLightbox('multiaddon');

        // Zobrazime loading
        lightbox.multiaddon.callbackLoading();

        // Zavolame addon
        await addonOrders(this, lightbox.multiaddon.ids, lightbox.multiaddon.addonId, items.filtered);

        // Zavolame callback
        lightbox.multiaddon.callback();
    }

    /**
     * Zavolanie exportu cez multiselect.
     */
    async multiExport() {
        const { lightbox } = this.state;
        const { items } = this.props;

        // Zavrieme lightbox
        this.closeLightbox('multiexport');

        // Zobrazime loading
        lightbox.multiexport.callbackLoading();

        let params = {};

        // Nasetujeme filter
        _.forEach(items.filtered, (value, key) => {
            params = { ...params, ...{ [`filter_${key}`]: value } };
        });

        await request(`/orders/multiExport/${lightbox.multiexport.ids}/${lightbox.multiexport.exportId}`, params).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                this.showSnackbar('error', __('Nepodarilo sa exportovať zoznam objednávok'));
                return;
            }

            // Stiahneme subor
            window.location = data.link;
        });

        // Zavolame callback
        lightbox.multiexport.callback();
    }

    /**
     * Vytvorenie faktur k objednavkam cez multiselect.
     *
     * @param {string|Array} ids
     * @param {function} callback
     */
    async multiInvoices(ids, callback) {
        const { createInvoices, items } = this.props;

        // Vytvorime faktury
        await createInvoices(this, ids, items.filtered);

        // Zavolame callback
        callback();
    }

    /**
     * Stiahnutie pdf objednavok cez multiselect.
     */
    async multiPdf() {
        const { lightbox } = this.state;
        const { items, user } = this.props;

        // Zavrieme lightbox
        this.closeLightbox('multipdf');

        // Zobrazime loading
        lightbox.multipdf.callbackLoading();

        let params = { merge_multi_pdf: user.settings.merge_multi_pdf };

        // Nasetujeme filter
        _.forEach(items.filtered, (value, key) => {
            params = { ...params, ...{ [`filter_${key}`]: value } };
        });

        await request(`/orders/multiPdf/${lightbox.multipdf.ids}`, params).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                this.showSnackbar('error', __('Nepodarilo sa stiahnuť zoznam pdf'));
                return;
            }

            // Nastavime pdfka ako precitane
            this.setState({ downloadedPdf: this.getDownloadedPdf(lightbox.multipdf.ids.split(',')) });

            // Stiahneme subor
            window.location = data.link;
        });

        // Zavolame callback
        lightbox.multipdf.callback();
    }

    /**
     * Uhradenie objednavok cez multiselect.
     *
     * @param {string|Array} ids
     * @param {function} callback
     */
    async multiPaid(ids, callback) {
        const { paidOrders, items } = this.props;

        // Uhradime objednavky
        await paidOrders(this, ids, items.filtered);

        // Zavolame callback
        callback();
    }

    /**
     * Zmazanie objednavok cez multiselect.
     */
    async multiDelete() {
        const { deleteOrders, items } = this.props;
        const { lightbox } = this.state;

        if (this.isDemo()) {
            this.showSnackbar('error', __('Objednávky nie je možné zmazať na DEMO účte'))
            this.closeLightbox('multidelete');
            return;
        }

        // Zavrieme lightbox
        this.closeLightbox('multidelete');

        // Zobrazime loading
        lightbox.multidelete.callbackLoading();

        // Zmazeme objednavky
        await deleteOrders(this, lightbox.multidelete.ids, items.filtered);

        // Zavolame callback
        lightbox.multidelete.callback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetchList(1, 0, items.filtered);
    }

    /**
     * Vratime id default exportu pdfka.
     *
     * @return {number}
     */
    getPdfOrderExportId() {
        const { items } = this.props;

        let export_id = 0;

        _.each(items.exports, item => {
            if (item.type === 'pdf' && toNumber(item.def) === 1) {
                // Default pdf
                export_id = item.id;
            }
        });

        return export_id;
    }

    /**
     * Event po zmene stavu objednavok v lightboxe.
     *
     * @param {string} value
     */
    onChangePrinter(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, printers: { ...lightbox.printers, selected: value } } });
    }

    /**
     * Event po zmene stavu objednavok v lightboxe.
     *
     * @param {string} value
     */
    onChangePrinterLoading(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, printers: { ...lightbox.printers, loading: value } } });
    }

    /**
     * Event po zmene stavu objednavok v lightboxe.
     *
     * @param {string} value
     */
    onChangeMultiState(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, multistate: { ...lightbox.multistate, stateId: value } } });
    }

    /**
     * Event po zmene addonu v lightboxe.
     *
     * @param {string} value
     */
    onChangeMultiAddon(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, multiaddon: { ...lightbox.multiaddon, addonId: value } } });
    }

    /**
     * Event po zmene exportu v lightboxe.
     *
     * @param {string} value
     */
    onChangeMultiExport(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, multiexport: { ...lightbox.multiexport, exportId: value } } });
    }

    /**
     * Rendrujeme screen.
     *
     * @return {JSX.Element|null}
     */
    renderScreen() {
        const { items, user, changeUserSetting, changeOrderData, editOrder, changeOrderState, sendOrders } = this.props;
        const { lightbox } = this.state;

        // Naformatujeme zoznam stavov
        const states = _.reduce(items.states, (result, { id, name }) => ({ ...result, [id]: name }), {});

        // Vytiahneme zoznam aktivnych addonov
        const addons = _.reduce(
            items.user_addons,
            (result, addon_id) => (_.has(items.addons, addon_id) ? { ...result, [addon_id]: items.addons[addon_id] } : result),
            {}
        );

        // Vytiahneme zoznam exportov
        const exports = _.reduce(items.exports, (result, { id, name, def }) => toNumber(def) === 0 ? ({ ...result, [id]: name }) : result, {});

        return (
            <div>
                {this.renderLightbox(
                    'sellers',
                    __('Predajca'),
                    !_.isEmpty(lightbox.sellers) ? <div>{lightbox.sellers.loading ? this.renderLoading(20) :
                        <div>
                            <div>
                                <Input
                                    label={__('Poznámka predajca')}
                                    value={lightbox.sellers.settings.goodeshop_poznamka_predajca}
                                    onChange={value => this.onChangeSellersSetting('goodeshop_poznamka_predajca', value)}
                                />
                            </div>
                            {user.is_seller_owner ? <div style={{ marginTop: 10 }}>
                                <Input
                                    label={__('Poznámka eshop')}
                                    value={lightbox.sellers.settings.goodeshop_poznamka_eshop}
                                    onChange={value => this.onChangeSellersSetting('goodeshop_poznamka_eshop', value)}
                                />
                            </div> : null}
                            <div style={{ marginTop: 10 }}>
                                <Input
                                    type="date"
                                    label={__('Dátum odovzdania')}
                                    value={lightbox.sellers.settings.goodeshop_datum_odovzdania}
                                    onChange={value => this.onChangeSellersSetting('goodeshop_datum_odovzdania', formatDate(value, 'yyyy-mm-dd'))}
                                />
                            </div>
                            {user.is_seller_owner ? <div style={{ marginTop: 10 }}>
                                <Select
                                    label={__('Výdajka')}
                                    options={{ Nie: __('Nie'), 'Áno': __('Áno') }}
                                    value={lightbox.sellers.settings.goodeshop_vydajka}
                                    onChange={value => this.onChangeSellersSetting('goodeshop_vydajka', value)}
                                    allowEmpty={false}
                                />
                            </div> : null}
                            {user.is_seller_owner ? <div style={{ marginTop: 10 }}>
                                <Select
                                    label={__('Zúčtované')}
                                    options={{ Nie: __('Nie'), 'Áno': __('Áno') }}
                                    value={lightbox.sellers.settings.goodeshop_zauctovana}
                                    onChange={value => this.onChangeSellersSetting('goodeshop_zauctovana', value)}
                                    allowEmpty={false}
                                />
                            </div> : null}
                            {user.is_seller_owner ? <div style={{ marginTop: 10 }}>
                                <Input
                                    label={__('Číslo faktúry')}
                                    value={lightbox.sellers.settings.goodeshop_cislo_faktury}
                                    onChange={value => this.onChangeSellersSetting('goodeshop_cislo_faktury', value)}
                                />
                            </div> : null}
                        </div>
                    }</div> : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.saveSellers(),
                    false,
                    () => this.closeSellers()
                )}
                {this.renderLightbox(
                    'multisellers',
                    __('Predajca'),
                    !_.isEmpty(lightbox.multisellers) ? <div>
                        <div>
                            <Input
                                label={__('Poznámka eshop')}
                                value={lightbox.multisellers.data.goodeshop_poznamka_eshop}
                                onChange={value => this.onChangeMultiSellersSetting('goodeshop_poznamka_eshop', value)}
                            />
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <Select
                                label={__('Zúčtované')}
                                options={{ Nie: __('Nie'), 'Áno': __('Áno') }}
                                value={lightbox.multisellers.data.goodeshop_zauctovana}
                                onChange={value => this.onChangeMultiSellersSetting('goodeshop_zauctovana', value)}
                            />
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <Input
                                label={__('Číslo faktúry')}
                                value={lightbox.multisellers.data.goodeshop_cislo_faktury}
                                onChange={value => this.onChangeMultiSellersSetting('goodeshop_cislo_faktury', value)}
                            />
                        </div>
                    </div> : null,
                    __('Áno'),
                    __('Nie'),
                    () => this.multiSellers()
                )}
                {this.renderLightbox(
                    'printers',
                    __('Vyberte tlačiareň'),
                    !_.isEmpty(lightbox.printers) ? <div>
                        {lightbox.printers.loading ? this.renderLoading(20) : <Select
                            options={lightbox.printers.items}
                            value={lightbox.printers.selected}
                            onChange={value => this.onChangePrinter(value)}
                            allowEmpty={false}
                        />}
                    </div> : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.savePrinters(),
                    false,
                    () => this.closePrinters()
                )}
                {this.renderLightbox(
                    'multiorderpdf',
                    __('Stiahnutie objednávok do pdf'),
                    !_.isEmpty(lightbox.multiorderpdf) ? <div>
                        <Checkbox
                            label={__('Zlúčiť do jedného pdf súboru')}
                            value={user.settings.merge_multi_order_pdf}
                            onChange={checked => changeUserSetting('merge_multi_order_pdf', checked)}
                        />
                    </div> : null,
                    __('Áno'),
                    __('Nie'),
                    () => this.multiOrderPdf()
                )}
                {this.renderLightbox(
                    'multistate',
                    __('Zmena stavu objednávok'),
                    !_.isEmpty(lightbox.multistate) ? <div>
                        <Select
                            options={states}
                            value={lightbox.multistate.stateId}
                            onChange={value => this.onChangeMultiState(value)}
                            allowEmpty={false}
                        />
                    </div> : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.multiChangeState()
                )}
                {this.renderLightbox(
                    'paid',
                    __('Naozaj chcete uhradiť túto objednávku?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.paid()
                )}
                {this.renderLightbox(
                    'state',
                    __('Naozaj chcete zmeniť stav tejto objednávke?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.changeState()
                )}
                {this.renderLightbox(
                    'delete',
                    __('Naozaj chcete zmazať túto objednávku?'),
                    !_.isEmpty(lightbox.delete) ? <div>
                        <Checkbox
                            label={__('Zmazať objednávku aj z eshopu')}
                            value={user.settings.order_delete_in_eshop}
                            onChange={checked => changeUserSetting('order_delete_in_eshop', checked)}
                        />
                    </div> : null,
                    __('Áno'),
                    __('Nie'),
                    () => this.delete()
                )}
                {this.renderLightbox(
                    'multiaddon',
                    __('Doplnok'),
                    !_.isEmpty(lightbox.multiaddon) ? <div>
                        <Select
                            options={addons}
                            value={lightbox.multiaddon.addonId}
                            onChange={value => this.onChangeMultiAddon(value)}
                            allowEmpty={false}
                        />
                    </div> : null,
                    __('Odoslať'),
                    __('Zrušiť'),
                    () => this.multiAddon()
                )}
                {this.renderLightbox(
                    'multiexport',
                    __('Export'),
                    !_.isEmpty(lightbox.multiexport) ? <div>
                        <Select
                            options={exports}
                            value={lightbox.multiexport.exportId}
                            onChange={value => this.onChangeMultiExport(value)}
                            allowEmpty={false}
                        />
                    </div> : null,
                    __('Exportovať'),
                    __('Zrušiť'),
                    () => this.multiExport()
                )}
                {this.renderLightbox(
                    'multipdf',
                    __('Stiahnutie faktúr do pdf'),
                    !_.isEmpty(lightbox.multipdf) ? <div>
                        <Checkbox
                            label={__('Zlúčiť do jedného pdf súboru')}
                            value={user.settings.merge_multi_pdf}
                            onChange={checked => changeUserSetting('merge_multi_pdf', checked)}
                        />
                    </div> : null,
                    __('Áno'),
                    __('Nie'),
                    () => this.multiPdf()
                )}
                {this.renderLightbox(
                    'multidelete',
                    __('Naozaj chcete zmazať označené objednávky?'),
                    <div>
                        <Checkbox
                            label={__('Zmazať objednávky aj z eshopu')}
                            value={user.settings.order_delete_in_eshop}
                            onChange={checked => changeUserSetting('order_delete_in_eshop', checked)}
                        />
                    </div>,
                    __('Áno'),
                    __('Nie'),
                    () => this.multiDelete()
                )}
                {this.renderDrawer('packages', {
                    title: __('Odoslanie objednávok'),
                    content: props => <Packages
                        user={user}
                        showSnackbar={(type, message) => this.showSnackbar(type, message)}
                        sendOrders={sendOrders}
                        {...props}
                    />,
                })}
                {this.renderDrawer('order', {
                    title: __('Objednávka'),
                    content: props => <Order
                        user={user}
                        showSnackbar={(type, message) => this.showSnackbar(type, message)}
                        changeOrderData={changeOrderData}
                        editOrder={editOrder}
                        changeOrderState={changeOrderState}
                        settings={items.settings}
                        userCouriers={items.user_couriers}
                        couriers={items.couriers}
                        courierSettings={items.courier_settings}
                        {...props}
                    />,
                })}
                {this.renderOffer()}
            </div>
        );
    }
}

const stateToProps = ({ orders, user }) => ({ items: orders, user });

export default withCookies(connect(stateToProps, {
    fetch: fetchOrders,
    clean: cleanOrders,
    setUser,
    changeUserSetting,
    syncOrder,
    syncOrders,
    pdfOrder,
    pdfOrders,
    changeOrdersState,
    changeOrderState,
    createInvoice,
    createInvoices,
    paidOrder,
    paidOrders,
    deleteOrder,
    deleteOrders,
    addonOrders,
    editOrder,
    changeOrderData,
    sendOrders,
    sellerOrder,
    sellerOrders,
})(OrdersScreen));
